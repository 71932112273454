import React from 'react'
import { Anchor, Divider } from 'antd'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import BlogPostWrapper from '../../components/BlogPost'
import { ExternalLink, HomeLink } from '../../components/BlogPost/Texts'
import { Thomas } from '../../components/BlogPost/Authors'
import makeStyles from '@mui/styles/makeStyles'
import SignupBelt from '../../components/Landing/SignupBelt'

const { Link: AnchorLink } = Anchor

const useStyles = makeStyles((theme) => ({
    smallText: {
        color: 'gray',
    },
    italic: {
        fontStyle: 'italic',
    },
    italic_underline: {
        fontStyle: 'italic',
        textDecoration: 'underline',
    },
    bold: {
        fontWeight: 'bold',
    },
}))

export default function ArticleStartStop({ setCookieValue, data }) {
    const classes = useStyles()
    const anchors = (
        <>
            <AnchorLink href="#titel" title="Intro" />
            <AnchorLink href="#skattekontrol" title="Skattekontrol" />
            <AnchorLink href="#skaerpet" title="Skærpet indsats" />
            <AnchorLink href="#ll33a.dk" title="ll33a.dk" />
            <AnchorLink href="#rapport" title="Compliance rapporter" />
            <AnchorLink href="#muligheder" title="Mange muligheder" />
        </>
    )

    return (
        <BlogPostWrapper
            title="Materialeindkaldelse"
            setCookieValue={setCookieValue}
            pathName="materiale-indkaldelse"
            anchors={anchors}
        >
            <div
                style={{
                    margin: 30,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    // flexWrap: 'wrap',
                    marginBottom: 100,
                }}
                id="titel"
            >
                <Img
                    title="Materialeindkaldelse"
                    alt="Materialeindkaldelse"
                    style={{ marginBottom: 100 }}
                    className="big-width"
                    fluid={data.s1.childImageSharp.fluid}
                />
                <p className="landing-guide-header-p">
                    <h1>“Skattestyrelsen har brug for oplysninger fra dig”</h1>
                    Dette er indledningen når Skattestyrelsen “inviterer” til torskegilde.
                    <br />
                    Med andre ord er du udtaget til skattekontrol*, når du modtager brev fra
                    Skattestyrelsen med ovenstående overskrift.
                    <br />
                    I denne artikel vil vi se nærmere på, hvilke oplysninger Skattestyrelsen beder
                    dig om at indsende, når du bliver udtaget til skattekontrol af et skatteår, hvor
                    du har oplyst* efter ligningslovens § 33 A (herefter benævnt LL§33A).
                    <br />
                    Vi ser også på, hvordan compliance værktøjet <HomeLink /> kan hjælpe dig i den
                    situation, og ikke mindst hvordan, du med værktøjet fremadrettet kan tilsikre
                    din compliance, for dermed at opnå din forventede skattenedsættelse.
                    <br />
                    <br />
                    <span id="skattekontrol" className={classes.bold}>
                        Skattekontrol af LL§33A skatteydere
                    </span>
                    <br />
                    Ved en skattekontrol anmoder Skattestyrelsen om materiale, for et givent
                    skatteår, der kan understøtte din berettigelse til skattelempelse i henhold til
                    LL§33A. Skattestyrelsen benævner også dette som en materialeindkaldelse.
                    <br />
                    Pga. af den længere ligningsfrist for udenlandsk indkomst kan materialet, som du
                    kan blive anmodet om at indsende, være op mod 3-4 år gammelt.
                    <br />
                    Skattestyrelsen beskriver det således:
                    <br />
                    <br />
                    <span className={classes.italic}>
                        “Personer med indkomst for arbejde udført i udlandet er ikke omfattet af den
                        korte ligningsfrist. Se § 1, stk. 2, nr. 5 i bekendtgørelse nr. 535 af 22.
                        maj 2013. Da du har indkomst for arbejde udført i udlandet, er du dermed
                        omfattet af den 3 årige lignings-frist.”
                    </span>
                    <br />
                    <br />
                    Udover dokumentation for, at du er berettiget til at benytte LL§33A, ønsker
                    Skattestyrelsen også i mange tilfælde, at materialet indeholder dokumentation
                    for din overholdelse af 6-måneders-reglen, samt 42-dages-reglen.
                    <br />
                    Netop den situation beskrives herunder, med et eksempel fra en skattekontrol af
                    en LL§33A skatteyder.
                    <SignupBelt includeLandingRef />
                    <span className={classes.bold}>
                        Oplysninger Skattestyrelsen har anmodet om ved skattekontrol af LL§33A
                        brugere
                    </span>
                    <br />
                    Skattestyrelsens afdeling “Udland 11” udsendte primo 2021, et stort antal
                    materialeindkaldelser vedr. skatteåret 2017. Her blev bl.a. personer med høj
                    mobilitet igen udtaget til kontrol. Alle de anmodet oplysninger blev i disse
                    indkaldelser inddelt i tre kategorier:
                    <br />
                    <span className={classes.italic_underline}>Vedrørende ansættelsesforhold:</span>
                    <br />
                    Her anmodes der om dokumentation for, at dit ansættelsesforhold opfylder
                    betingelserne ved at du har erhvervet lønindkomst ved personligt arbejde i et
                    tjenesteforhold i udlandet.
                    <br />
                    <span className={classes.italic_underline}>Vedrørende udlandsforhold:</span>
                    <br />
                    Her anmodes der om oplysninger der kan afgøre hvorvidt du er begrænset eller
                    fuld skattepligtig til Danmark.
                    <br />
                    <span className={classes.italic_underline}>
                        Vedrørende lempelse efter ligningslovens § 33 A:
                    </span>
                    <br />
                    Her anmodes der om oplysninger, der kan understøtte at du har overholdt
                    6-måneders-reglen og 42-dages-reglen.
                    <br />
                    <br />
                    Nedenstående liste med 17 punkter, er fra en skattekontrol, hvor Skattestyrelsen
                    har anmodet om oplysninger fra en skatteyder ansat inden for luftfart.
                    <br />
                    <br />
                    <span className={classes.italic_underline}>Vedrørende ansættelsesforhold:</span>
                    <br />
                    <span className={classes.italic}>
                        <ul className="decimal">
                            <li>
                                Specifikation af selvangivet lønindkomst på x kr., herunder kopi af
                                samtlige lønsedler for perioden.
                            </li>
                            <li>Ansættelseskontrakt vedrørende dine arbejdsforhold i udlandet.</li>
                            <li>
                                Arbejdsplaner, der viser hvornår du har været på arbejde. Anvendes
                                der koder i arbejdsplanerne, bedes disse forklaret.
                            </li>
                            <li>
                                Logbog, der viser hvilke destinationer du har fløjet til og fra.{' '}
                            </li>
                            <li>
                                Dokumentation for samtlige udbetalinger fra din arbejdsgiver. Dette
                                gælder både skattefrie og skattepligtige beløb. Herunder
                                rejsegodtgørelser og refusion af omkostninger. Hvis du har modtaget
                                refusion af omkostninger, som du har afholdt for din arbejdsgiver
                                bedes også dokumentation for, at du har afholdt udgifterne, f. eks.
                                i form af kontoudtog hvoraf betalingerne fremgår.
                            </li>
                            <li>
                                Specifikation af modtagne skattefri rejse- og
                                befordringsgodtgørelser (x kr.), herunder oplysning om hvilke dage
                                der er modtaget godtgørelser for.
                            </li>
                            <li>
                                Dokumentation for samtlige personalegoder, herunder frie goder,
                                medarbejderaktier, sundhedsforsikringer og rabatkøb af
                                rejser/flybilletter.
                            </li>
                            <br />
                            <span className={classes.italic_underline}>
                                Vedrørende udlandsforhold:
                            </span>
                            <br />
                            <li>
                                Dokumentation for udenlandsk formue, herunder fast ejendom,
                                bankkonti, værdipapirer og pensionsdepoter. Hvis du ikke har formue
                                i udlandet, bedes du bekræfte dette.
                            </li>
                            <li>
                                Det fremgår af dine folkeregisteroplysninger, at du har været
                                tilmeldt en adresse i udlandet siden den x. november xxxx. Du bedes
                                i denne forbindelse redegøre nærmere for dine bopælsforhold,
                                herunder baggrunden for at du mener at du er fuldt skattepligtig til
                                Danmark.
                            </li>
                            <br />
                            <span className={classes.italic_underline}>
                                Vedrørende lempelse efter ligningslovens § 33 A:
                            </span>
                            <br />
                            <li>
                                Oplysning om startdato (udrejsedag) for den eller de perioder der
                                berettiger til lempelse efter ligningslovens § 33 A.
                            </li>
                            <li>
                                Specificeret opgørelse over de kalenderdage, hvor du har opholdt dig
                                i henholdsvis Danmark og udlandet, herunder opgørelse over
                                rejsedage, hvor du rejser ind og ud af Danmark.
                            </li>
                            <li>
                                Dokumentation for hvorledes befordring mellem hjem og arbejde er
                                foregået, herunder oplysning om hvem, der har afholdt
                                omkostningerne.
                            </li>
                            <li>
                                Dokumentation for at du har opholdt dig uden for det danske rige i
                                en eller flere perioder af mindst 6 måneders varighed. Eksempelvis
                                kreditkorthævninger, hotelregninger, rejseomkostninger eller
                                lignende.
                            </li>
                            <li>
                                Dokumentation for at du ikke har opholdt dig, inden for det danske
                                rige mere end 42 dage inden for enhver 6 måneders periode, i
                                forbindelse med ferie eller lignende.
                            </li>
                            <li>
                                Logbog hvori du markerer de flyvninger, hvor du har fløjet gennem
                                dansk luftrum. Luftrummet over Danmark indbefatter luftrummet over
                                Færøerne, Grønland og Danmark.
                            </li>
                            <li>Arbejdsplaner der viser hvornår, du har været på arbejde.</li>
                            <li>
                                Hvis en periode starter forud for 2017, udbedes materialet også for
                                den relevante del af 2016. Hvis en periode påbegyndes i 2017 og
                                fortsætter ind i 2018, udbedes materialet også for den relevante del
                                af 2018.
                            </li>
                        </ul>
                    </span>
                    <br />
                    <br />
                    <span id="skaerpet" className={classes.bold}>
                        Skærpet kontrol indsats
                    </span>
                    <br />
                    Ved tidligere skattekontroller, fremsendte Skattestyrelsen en kalender, som
                    skatteyderen skulle udfylde med ferie eller lignende ophold afholdt inden for
                    det danske rige (herefter benævnt DK). Denne kontrol metode er nu sjældent
                    forekommen, hvis overhovedet? Hvorimod en skærpelse af kontrolindsatsen er set
                    gennem de senere år, hvor Skattestyrelsen har ændret praksis.
                    <br />
                    Det skal hertil nævnes, at ordet praksisændring har juridiske implikationer,
                    hvorfor Skattestyrelsen ikke benævner deres ændrede fremgangsmåde, som en
                    praksisændring.
                    <br />
                    (En praksisændring skal f.eks. være varslet, og må ikke være bagudrettet)
                    <br />
                    Med den skærpede kontrol indsats, afkræver man nu skatteyderen et langt mere
                    omfattende materiale end tidligere.
                    <br />
                    Især specifikke personale grupper indenfor luftfart oplever en mere nidkær
                    skattekontrol.
                    <br />
                    <br />
                    Generelt er det Skattestyrelsens vurdering, at det er dig der har bevisbyrden
                    for, at du opfylder betingelserne for lempelse efter LL§33A. Hertil kommer
                    Skattestyrelsens nye holdning, hvor man ifb. med 42-dages-reglen, som
                    udgangspunkt antager, at du opholder dig i DK, med mindre du dagligt
                    dokumenterer, at du opholder dig i udlandet.
                    <br />
                    Dette, i kombinationen med den længere ligningsfrist, har gennem de senere år
                    øget skatte provenuet betragteligt. I mange tilfælde har det nemlig vist sig, at
                    det kan være særdeles vanskeligt, for en skatteyder ved skattekontrol,
                    bagudrettet at kunne redegøre fyldestgørende for, at de på daglig basis, har
                    opholdt sig i udlandet i henhold til 42-dages-reglen.
                    <br />
                    Hvorfor de efterfølgende ikke har fået deres skattelempelse godkendt.
                    <br />
                    <br />
                    Det må således forventes, at for at kunne få godkendt sin skattelempelse ved en
                    skattekontrol, skal der nu bruges ikke uvæsentlige ressourcer på, at udarbejde
                    det anmodede materiale til Skattestyrelsen.
                    <br />
                    Særligt punkterne 11, 12, 13 {'&'} 14, kan være tidskrævende og forbundet med en
                    relativ stor arbejdsbyrde at besvare fyldestgørende med bl.a. objektiv
                    dokumentation.
                    <br />
                    Naturligvis alt afhængig af, hvor godt du har forberedt dig på en skattekontrol.
                    <SignupBelt includeLandingRef />
                    <h3 id="ll33a.dk">
                        <HomeLink /> - et compliance værktøj
                    </h3>
                    Med compliance-værktøjet <HomeLink />, er du godt hjulpet.
                    <br />
                    Både når det gælder at besvare Skattestyrelsens henvendelse vedr. et tidligere
                    skatteår, men også fremadrettet, når du vil forberede dig selv på en kommende
                    skattekontrol.
                    <br />
                    I begge tilfælde kan du overlade arbejdsbyrden til værktøjet, der udfærdiger
                    rapporter over din compliance af LL§33A. Du skal blot angive din start og
                    slut-dato for din(e) LL§33A udlandsperioder, dato’er for ophold i DK, samt
                    uploade din dokumentation.
                    <br />
                    <br />
                    <HomeLink /> dokumenterer hvorvidt du overholder både 6-måneders-reglen samt
                    42-dages-reglen, når du indsætter din start- og slut-dato for
                    udlandsperioden(erne), samt dine ophold i DK, på kalender-siden. Herefter har du
                    det fulde overblik på graf-siden.
                    <br />
                    Denne dokumentation er dog at betragte som subjektiv, hvorfor Skattestyrelsen
                    også efterspørger objektiv dokumentation for, at du har opholdt dig i udlandet.
                    <br />
                    Det kan bl.a. være; rejsedokumentation i form af boardingpass for fly- og
                    færge-rejser, køre-bog, samt bilag for; brændstof indkøb, kreditkort hævning,
                    dagligvarer indkøb, shopping, restaurant besøg, etc.
                    <br />
                    Med <HomeLink /> installeret på din smartphone, er det nemt med kameraet at
                    registrere og arkivere dine indsamlede bilag for de relevante datoer på
                    kalender-siden.
                    <br />
                    <br />
                    <span className={classes.italic}>
                        Omfang og indhold af objektiv dokumentation ser vi nærmere på i den kommende
                        artikel: “Blanke dage - Skattestyrelsens udgangspunkt”, hvor vi bl.a.
                        berører Skattestyrelsens holdning, der som udgangspunkt antager, at du
                        opholder dig i Danmark, med mindre du dokumenterer, at du opholder dig i
                        udlandet.
                    </span>
                    <br />
                    <br />
                    Med ovennævnte arbejdsgange udført, ligger dine data nu i skyen, krypteret og
                    sikret med backup på servere, som naturligvis er GDPR compliant.
                    <br />
                    I dagligdagen giver dette dig et unikt indblik i din compliance af LL§33A.
                    <br />
                    Du kan bl.a. hurtigt overskue konsekvenserne ifh. til 42-dages-reglen, når du
                    f.eks. tilføjer eller ændrer på planlagte ferie ophold i DK. På kalender-siden
                    er det også nemt at få overblik over dine blanke dage. (Dage hvorpå du endnu
                    ikke har uploaded objektiv dokumentation)
                    <br />
                    <br />
                    <span id="rapport" className={classes.bold}>
                        Compliance rapporter
                    </span>
                    <br />
                    Når du indkaldes til skattekontrol, åbner du blot "Compliance Reports” på
                    settings-siden. Her bindes alle dine data sammen til en færdig compliance
                    rapport.
                    <br />
                    Fra din laptop/desktop, vælger du det indkaldte skatteår, og systemet udarbejder
                    herefter automatisk en komplet compliance rapport for hver af dine, for
                    skatteåret relevante, udlandsperioder.
                    <br />
                    <br />
                    Compliance rapporten, er en komplet oversigt over din udlandsperiode, hvor dine
                    data er sat i forhold til:
                    <ul className="circled">
                        <li>Udlandsperiodens længde vs. 6-måneders-reglen</li>
                        <li>Ferie el. lign. ophold i DK vs. 42-dages-reglen</li>
                        <li>Dage i udlandet med appendix reference til objektiv dokumentation</li>
                        <li>Angivelse af opholdsteder (f.eks. via Geo-Snapshot funktion)</li>
                        <li>Dato angivelse af arbejdsdage i udlandet</li>
                        <li>
                            Dine bemærkninger til en given dato. (F.eks. angivelse af ferie i
                            udlandet)
                        </li>
                    </ul>
                    Til hver compliance rapport kompileres en appendix mappe, der inderholder filer
                    med din uploadede dokumentation, som er indekseret efter dato reference.
                    <br />
                    <br />
                    Herefter har du mulighed for at sende link til Skattestyrelsen, der således
                    direkte fra <HomeLink />
                    's servere, kan downloade din compliance rapport, inkl. den ofte data tunge
                    appendix mappe. Du kan naturligvis også vælge selv, at downloade compliance
                    rapport og appendix mappe, for herefter selv at e-maile disse. Vi har dog set
                    eksempler på appendix mapper på op til 1,5GB, hvilket kan være udfordrende at
                    håndtere via e-mail.
                    <br />
                    <br />
                    <span className={classes.bold}>
                        Skattekontrol af ét skatteår, indrager ofte de tilstødende skatteår
                        <br />
                        -Compliance rapporten tager højde for dette, og medtager relevante perioder
                    </span>
                    <br />
                    Ved kontrol af et enkelt skatteår, kan det blive relevant, at medtage op til 6
                    måneder af det foregående/efterfølgende skatteår i compliance rapporten.
                    <br />
                    Årsagen hertil er, at såfremt en udlandsperiode løber hen over et årsskifte, vil
                    der være behov for at kontrollere den del af udlandsperioden, der rækker ind i
                    tilstødende skatteår. Dette for at kunne afgøre hvorvidt 42-dages-reglen er
                    fulgt i enhver 6 måneders periode, af pågældende udlandsperiode. Eksempelvis vil
                    en skatteryder med en udlandsperiode, der starter d. 14.05.2016 og løber frem
                    til d. 03.08.2018, og som indkaldes til skattekontrol for skatteåret 2017,
                    skulle medtage sidste 6 mdr. af 2016, samt første 6 mdr. af 2018.
                    <br />
                    Du kan også have flere udlandsperioder der er relevante for et enkelt skatteår.
                    Dette gælder, hvis du i skatteåret der kontroleres,{' '}
                    <ExternalLink to="https://ll33a.dk/blog/start-og-ophor">
                        afslutter en udlandsperiode efter loven
                    </ExternalLink>
                    , for herefter i samme skatteår, at påbegynde en ny udlandsperiode. Har du flere
                    udlandsperioder i et skatteår, vil én eller to af disse udlandsperioder, som
                    logisk følge heraf, løbe hen over et årsskifte. Hvorved det altså er relevant,
                    at medtage op til 6 måneder af de(t) tilstødende skatteår. Dette tages der højde
                    for i compliance rapporterne.
                    <br />
                    Du skal blot vælge skatteåret der er indkaldt til kontrol, systemet vil så
                    automatisk udarbejde en compliance rapport for hver udlandsperiode du måtte have
                    haft i det givne år, samt medtage relevante perioder i tilstødende skatteår.
                    <br />
                    <br />
                    <span id="muligheder" className={classes.bold}>
                        Mange muligheder med dine data i skyen…
                    </span>
                    <br />
                    At digitalisere og lægge sin LL§33A compliance op i skyen, er der mange fordele
                    i. Både når det gælder at forberede sig på en fremtidig skattekontrol, men også
                    når det drejer sig om, at skulle håndtere en skattekontrol af et tidligere
                    skatteår.
                    <SignupBelt includeLandingRef />
                    <span className={classes.bold}>…nem adgang til dit compliance værktøj</span>
                    <br />
                    Du kan tilgå din konto på <HomeLink /> når-som-helst og hvor-som-helst du har
                    internet adgang.
                    <br />
                    De fleste platforme og web-browsere understøttes, ved at logge ind på{' '}
                    <HomeLink /> via web-browser.
                    <br />
                    På IOS og Android enheder, installerer du app’en ved at tilføje til hjemmeskærm.
                    <br />
                    Med app’en på mobilen, har du altid dit compliance værktøj lige ved hånden.
                    <br />
                    I det daglige, vil du formentlig også opleve, at du oftest vil bruge app’en
                    netop på mobilen.
                    <br />
                    Skal du håndtere compliance rapporter, anbefales det, at logge ind fra din
                    desktop/laptop pga. skærmstørrelse og dermed det bedre overblik.
                    <br />
                    <br />
                    <span className={classes.bold}>
                        …indsamling og opbevaring af objektiv dokumentation
                    </span>
                    <br />
                    Det tager blot få sekunder om dagen mens du er på farten, at registrerer dit
                    bilag til objektiv dokumentation. Dette gør du med app’en og kameraet på din
                    smartphone.
                    <br />
                    Bilaget ligger nu klar i skyen, og indekseret til din compliance rapport i den
                    tilhørende appendix mappe. De klare fordele er:
                    <ul className="circled">
                        <li>at du nu ikke længere skal bruge tid på bilaget</li>
                        <li>
                            at du altid har adgang og mulighed for at adminsitrere bilaget i skyen
                        </li>
                        <li>
                            at eliminere risikoen for, at bilag bortkommer eller går tabt, som det
                            sker ved f.eks., at teksten forsvinder over tid på bonner udskrevet på
                            termo-papir
                        </li>
                        <li>
                            at ved en skattekontrol har <HomeLink /> automatiseret den videre
                            håndtering af bilaget for dig gennem compliance rapporten
                        </li>
                    </ul>
                    <span className={classes.bold}>…del din virkelighed med familien</span>
                    <br />
                    Som LL§33A skatteyder er man ofte adskilt fra familien, og det kan til tider
                    være en udfordring at få familie-kalenderen planlagt, og på samme tid tage højde
                    for 42-dages-reglen.
                    <br />
                    Dette er nu blevet væsentligt lettere med <HomeLink />.
                    <br />
                    Med <HomeLink /> kan du tilrettelægge dine fremtidige ophold i DK, i fuld
                    compliance med 42-dages-reglen. Dette kan tilmed gøres online sammen med
                    familien således, at når du er på farten i udlandet, og familien er hjemme, kan
                    i sammen se din compliance af 42-dages-reglen, efterhånden som i lægger
                    fremtidige ferie ophold ind i kalenderen.
                    <br />
                    <br />
                    <span className={classes.bold}>…del din data med din rådgiver</span>
                    <br />
                    På trods af, at du med <HomeLink /> er selvkørende hvad angår din compliance af
                    LL§33A, kan der være tilfælde, hvor du ønsker rådgivning omkring dine forhold
                    vedr. LL§33A.
                    <br />
                    I disse tilfælde har du og din rådgiver god nytte af dine data i skyen.
                    <br />
                    Ønsker du f.eks. ikke selv at håndtere din kontakt med Skattestyrelsen vedr. din
                    Skattekontrol, eller ved anden behov for rådgivning, kan du give din rådgiver
                    (revisor/advokat) adgang til dine data gennem <HomeLink />
                    's RevisorPlatform.
                    <br />
                    Rådgivere der er oprettet på RevisorPlatformen, kan tilgå dine data i et format
                    der hurtigt giver overblik over din situation, samt mulighed for at administrere
                    dine data på dine vegne. Herunder at fremsende din compliance rapport digitalt
                    til Skattestyrelsen. Naturligvis alt efter dine valg af settings for deling, i
                    henhold til jeres indbyrdes aftale, rådgiver og dig imellem.
                    <br />
                    Dette sparer både dig selv og din rådgiver tid. Således kan din rådgiver alene
                    koncentrere sig om, at give dig faglig rådgivning omkring din sag, fremfor at
                    bruge tid på at håndtere alt det praktiske omkring håndtering af bilag,
                    dagsberegning og ikke mindst fremsendelse til Skattestyrelsen.
                    <br />
                    <br />
                    <span className={classes.bold}>…flere mulighder for data deling</span>
                    <br />
                    Med dine data i skyen, har du ikke blot mulighed for, at dele din data med din
                    rådgiver gennem RevisorPlatformen. Du kan også dele dine færdige compliance
                    rapporter med rådgivere eller andre der ikke er oprettet på RevisorPlatformen.
                    Dette sker via hyperlink der sendes til den af dig specificerede modtagers mail.
                    <br />
                    Du kan selv bestemme hvor dyb en adgang til dine data, som modtageren af dit
                    link skal have. Du kan bl.a. vælge om din rapport alene skal være tilgængelig i
                    en web-browser, eller om du vil tillade at din rapport kan downloades direkte
                    fra <HomeLink />
                    ’s server, inkl. appendix mapperne med din indsamlede dokumentation.
                    <br />
                    Du har således fuld kontrol over dine data, og du kan til hver en tid annullere
                    dine delingstilladelser.
                    <br />
                    <br />
                    <h3>
                        Compliance værktøjet holder dig fri af faldgruberne i ligningslovens § 33 A
                    </h3>
                    Med <HomeLink /> værktøjet lige ved hånden, får du overblik og kontrol med din
                    compliance gennem:
                    <ul className="circled">
                        <li>Automatisk beregning af 6-måneders-reglen.</li>
                        <li>
                            Automatisk beregning af 42-dages-reglen inkl. fremtidige planlagte
                            ophold i DK.
                        </li>
                        <li>
                            Arkivsystem i skyen til indsamling og opbevaring af objektiv
                            dokumentation.
                        </li>
                        <li>
                            Mulighed for online deling af dine data. (f.eks. ved behov for
                            rådgivning)
                        </li>
                        <li>
                            Autogenerering af compliance rapporter til brug ved indkaldelse til
                            skattekontrol.
                        </li>
                    </ul>
                    Dermed elimineres risikoen for, at snuble i egne opgørelser og fortolkninger af
                    dags- og måneds-regler, hvilket desværre ofte ses i retsafgørelser, med tab af
                    skattelempelse til følge.
                    <br />
                    Læg dertil Skattestyrelsens skærpede kontrol indsats, og behovet for et
                    compliance værktøj til LL§33A skatteydere syntes kun stører.
                    <br />
                    <br />
                    Du er velkommen til at prøve selv.
                    <br />
                    Start allerede i dag, og reducer dermed arbejdsbyrden væsentligt ved din næste
                    skattekontrol.
                    <br />
                    Tilmeld dig en 30 dages gratis prøveperiode her:{' '}
                    <ExternalLink to="https://ll33a.dk/signup">ll33a.dk/signup</ExternalLink>
                    <br />
                    <br />
                    <span className={classes.italic}>
                        *Revisionen af skattekontrolloven har ført til ændringer af terminologien:
                        <br />
                        Fra skatteligning til skattekontrol:
                        <br />
                        Skattekontrolloven anvender nu udtrykket skattekontrol.
                        <br />
                        Fra selvangivelse til oplysning:
                        <br />
                        Udtrykket "at selvangive" erstattes af udtrykket "at oplyse".
                        <br />
                        Læs mere på Skattestyrelsen’s hjemmeside under afsnittet: “
                        <ExternalLink to="https://info.skat.dk/data.aspx?oid=2060309">
                            Ændret terminologi
                        </ExternalLink>
                        ”
                    </span>
                </p>
            </div>
            <SignupBelt includeLandingRef />
            <p className={classes.smallText}>Sidst opdateret: 8. december 2021.</p>
            <Divider />
            <p className={classes.smallText}>Forfatter:</p>
            <Thomas />
        </BlogPostWrapper>
    )
}

export const query = graphql`
    query {
        s1: file(relativePath: { eq: "mat-indkald.png" }) {
            childImageSharp {
                fluid(maxWidth: 300) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`
